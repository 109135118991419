import React from 'react'
import './index.css'
import Header from '../../components/Header'
import SettingsMenu from '../../components/SettingsMenu'
import { Link } from 'react-router-dom'
import { ContractIcon, InsuranceIcon } from '../../assets'

const Settings = () => {
  return (
    <div className='home' >
      <Header />
      <div className='body' >
        <div className='settings-home' >
          <SettingsMenu page={"settings"} />
          <div className='settings-inner' >
            <b>Settings</b>
            <Link to={"/privacy-policy"} ><img src={InsuranceIcon} /> <span>Privacy Policy</span></Link>
            <Link to={"/terms-of-use"} ><img src={ContractIcon} /> <span>Terms Of Use</span></Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Settings


// You are an excellent lawyer.

// I need your help to write a simple privacy policy for my website. Here is some context:
// - Website: https://therapyai.app
// - Name: TherapyAI
// - Description: It is an AI theraphist and mental healt firend that speak with the user.
// - User data collected: name, email
// - Non-personal data collection: web cookies
// - Purpose of Data Collection: Order processing
// - Data sharing: we do not share the data with any other parties
// - Children's Privacy: we do not collect any data from children
// - Updates to the Privacy Policy: users will be updated by email
// - Contact information: hello@retryapps.co

