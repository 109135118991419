import React, { useEffect, useState } from 'react'
import './index.css'
import AudioSpectrum from '../AudioSpectrum'
import { RetellWebClient } from "retell-client-js-sdk";
import callStore from '../../stores/callStore';
import PrimaryButton from '../PrimaryButton';
import loadingStore from '../../stores/loadingStore';

const webClient = new RetellWebClient();

const Call = () => {
  const { isCalling } = callStore;

  useEffect(() => {

    startConversation();

    webClient.on("call_started", () => {
      console.log("call started");
    });

    webClient.on("call_ended", () => {
      console.log("call ended");
    });

    // When agent starts talking for the utterance
    // useful for animation
    webClient.on("agent_start_talking", () => {
      console.log("agent_start_talking");
    });

    // When agent is done talking for the utterance
    // useful for animation
    webClient.on("agent_stop_talking", () => {
      console.log("agent_stop_talking");
    });

    // Real time pcm audio bytes being played back, in format of Float32Array
    // only available when emitRawAudioSamples is true
    webClient.on("audio", (audio) => {
      console.log(audio);
    });

    // Update message such as transcript
    webClient.on("update", (update) => {
      console.log(update);
    });

    webClient.on("metadata", (metadata) => {
      console.log(metadata);
    });

    webClient.on("error", (error) => {
      console.error("An error occurred:", error);
      // Stop the call
      webClient.stopCall();
    });

  }, []);


  const startConversation = async () => {
    try {
      const registerCallResponse = callStore.call;

      if (registerCallResponse.access_token) {
        webClient
          .startCall({
            accessToken: registerCallResponse.access_token,
          })
          .catch(console.error);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const stopConversation = async () => {
    try {
      webClient.stopCall();
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const stopCall = async () => {
    loadingStore.setIsloading(true);
    stopConversation()
    callStore.setIsCalling(false)
    await callStore.all();
    loadingStore.setIsloading(false);
  }


  return (
    <div className='call-home' >
      <AudioSpectrum />
      <div className='stop-call-container' >
        <PrimaryButton text={"Stop Therapy"} action={stopCall} />
      </div>
    </div>
  )
}

export default Call
