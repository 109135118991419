import React from 'react'
import './index.css'
import PrimaryButton from '../PrimaryButton'
import callStore from '../../stores/callStore'
import loadingStore from '../../stores/loadingStore'


const NoCallHome = () => {

  const startCall = async () => {
    loadingStore.setIsloading(true);
    await callStore.create();
    callStore.setIsCalling(true)
    loadingStore.setIsloading(false);
  }

  return (
    <div className='no-call-home' >
      <PrimaryButton text={"Start Therapy"} action={startCall} />
    </div>
  )
}

export default NoCallHome
