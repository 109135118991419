import React from 'react';
import Header from '../../components/Header';

const TermsOfUse = () => {
  return (
    <div className='text-page-container' >
      <Header />
      <div className='text-page'>
        <h1>Terms of Use</h1>
        <p>Welcome to TherapyAI, an AI therapist and mental health friend that communicates with users. By accessing and using our website, you agree to the following terms and conditions:</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By using TherapyAI, you agree to comply with and be bound by these Terms of Use. If you do not agree with any part of these terms, you must not use our website.</p>

        <h2>2. Data Collection</h2>
        <p>We collect the following user data:</p>
        <ul>
          <li>Name</li>
          <li>Email</li>
        </ul>
        <p>We also collect non-personal data through the use of web cookies.</p>

        <h2>3. Purpose of Data Collection</h2>
        <p>The data we collect is used solely for order processing purposes. We do not share this data with any other parties.</p>

        <h2>4. Children's Privacy</h2>
        <p>We do not collect any data from children. If you are under the age of 13, please do not use this website.</p>

        <h2>5. Updates to the Terms of Use</h2>
        <p>We may update these Terms of Use from time to time. Users will be notified of any changes via email.</p>

        <h2>6. Contact Information</h2>
        <p>If you have any questions or concerns regarding these Terms of Use, please contact us at <a href="mailto:hello@retryapps.co">hello@retryapps.co</a>.</p>

        <h2>7. Governing Law</h2>
        <p>These Terms of Use are governed by and construed in accordance with the laws of the jurisdiction in which TherapyAI operates.</p>

        <h2>8. Limitation of Liability</h2>
        <p>TherapyAI is an AI-based platform and does not replace professional medical or therapeutic advice. Users should consult with a licensed therapist or healthcare provider for any mental health concerns. TherapyAI is not liable for any damages arising from the use of the platform.</p>

        <p>By using TherapyAI, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>
      </div>
    </div>
  );
};

export default TermsOfUse;
