import React from 'react'
import './index.css'

const PrimaryButton = ({ text, action }) => {

  return (
    <div onClick={() => { action() }} className='start-therapy-btn' >
      {text}
    </div>
  )
}

export default PrimaryButton
