// useAudioAnalyzer.js
import { useEffect, useState, useRef } from 'react';

export const useAudioAnalyzer = () => {
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState([]);
  const audioContextRef = useRef(null);
  const userSourceRef = useRef(null);
  const retelaiSourceRef = useRef(null);
  const analyserRef = useRef(null);
  const mergerRef = useRef(null);

  useEffect(() => {
    const getUserAudio = async () => {
      const userStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      userSourceRef.current = audioContextRef.current.createMediaStreamSource(userStream);
      setupAnalyzer();
    };

    const setupAnalyzer = () => {
      analyserRef.current = audioContextRef.current.createAnalyser();
      mergerRef.current = audioContextRef.current.createChannelMerger(2);

      userSourceRef.current.connect(mergerRef.current, 0, 0);

      // Placeholder for Retelai source, replace with actual Retelai audio stream
      const retelaiStream = audioContextRef.current.createMediaStreamDestination().stream;
      retelaiSourceRef.current = audioContextRef.current.createMediaStreamSource(retelaiStream);
      retelaiSourceRef.current.connect(mergerRef.current, 0, 1);

      mergerRef.current.connect(analyserRef.current);

      analyserRef.current.fftSize = 4096;
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      setAnalyser(analyserRef.current);
      setDataArray(dataArray);
    };

    getUserAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const getFrequencyData = () => {
    if (analyser) {
      analyser.getByteFrequencyData(dataArray);
    }
    return dataArray;
  };

  return getFrequencyData;
};
