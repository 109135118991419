import { useNavigate } from "react-router-dom";
import { UserIcon, BillingIcon, SettingsIcon, LogoutIcon } from "../../assets"
import authStore from "../../stores/authStore";
import './index.css'

const SettingsMenu = ({ page }) => {

  const nav = useNavigate();

  const goPage = (q) => {
    nav(q);
  }

  const logout = () => {
    authStore.logout()
    nav("/login")
  }

  return (
    <div className="settings-menu" >
      <button onClick={() => goPage("/account")} style={{ backgroundColor: page === "account" && "#424242" }} ><img src={UserIcon} /> <span>Account</span></button>
      <button onClick={() => goPage("/billing")} style={{ backgroundColor: page === "billing" && "#424242" }} ><img src={BillingIcon} /> <span>Billing</span></button>
      <button onClick={() => goPage("/settings")} style={{ backgroundColor: page === "settings" && "#424242" }} ><img src={SettingsIcon} /> <span>Settings</span></button>
      <div className='line' ></div>
      <button onClick={() => logout()} ><img src={LogoutIcon} /> <span>Log out</span></button>
    </div>
  )
}

export default SettingsMenu