import apiManager from "../Apimanager";

class AuthStore {

  login = async (config) => {
    try {

      const resp = await apiManager.request("/auth/login", "post", config);
      return resp;

    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

  register = async (config) => {
    try {

      const resp = await apiManager.request("/auth/register", "post", config);
      return resp;

    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

  logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
  }

}

const authStore = new AuthStore();
export default authStore;