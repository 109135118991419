import React from 'react'
import './index.css'
import Header from '../../components/Header'
import SettingsMenu from '../../components/SettingsMenu'

const Billing = () => {
  return (
    <div className='home' >
      <Header />
      <div className='body' >
        <div className='billing-home' >
          <SettingsMenu page={"billing"} />
        </div>
      </div>

    </div>
  )
}

export default Billing
