import { observer } from 'mobx-react-lite'
import React from 'react'
import { Digital } from 'react-activity'
import "react-activity/dist/library.css";
import './index.css'

const Loading = observer(() => {
  return (
    <div className='loading' >
      <Digital color='white' size={100} />
    </div>
  )
})

export default Loading
