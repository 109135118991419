import { useEffect, useState } from 'react'
import './index.css'
import callStore from '../../stores/callStore'
import { formatTimestamp, getDuration } from '../../helpers/functions';
import { CloseIcon, TimeIcon } from '../../assets';
import { Link } from 'react-router-dom';

const CallDetail = ({ call_id }) => {


  const [call, setCall] = useState(null);
  const { calls } = callStore;

  useEffect(() => {
    const call = calls.find(c => c.call_id == call_id);
    setCall(call);
  }, [call_id])


  if (call) {
    const call_summary = call.call_analysis?.call_summary;
    const start_timestamp = call?.start_timestamp;
    const end_timestamp = call?.end_timestamp;
    const transcript_object = call?.transcript_object;

    return (
      <div className='call-detail' >
        <Link to={"/home"} ><img src={CloseIcon} /></Link>
        <b>Therapy Analysis</b>

        <div className='time' >
          <img src={TimeIcon} />
          <span>{`${formatTimestamp(start_timestamp)} - ${getDuration(start_timestamp, end_timestamp)}`}</span>
        </div>

        <div className='summary' >
          <span>Summary</span>
          <p>{call_summary}</p>
        </div>

        {
          transcript_object &&
          <div className='conversation' >
            <span>Conversation</span>
            <div className='chat' >
              {
                transcript_object.map(message => {
                  return (
                    <div className={`chat-box ${message.role}-chat-box`} >
                      <span>{message.content}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }

      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default CallDetail
