import React, { useEffect, useState } from 'react'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import apiManager from '../../Apimanager';
import { Link, useNavigate } from 'react-router-dom';
import loadingStore from '../../stores/loadingStore'
import './index.css'
import { Logo } from '../../assets';
import authStore from '../../stores/authStore';


const Login = () => {

  const nav = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);
  const [config, setConfig] = useState({});

  useEffect(() => {

    const token = localStorage.getItem("token");
    if (token) {
      nav("/home")
    }

  }, [loginStatus])

  const responseMessage = async (response) => {
    loadingStore.setIsloading(true);
    const resp = await apiManager.request("/auth/google", "post", { googleToken: response.credential })
    if (resp.done) {
      localStorage.setItem("token", resp.data.token)
      localStorage.setItem("user", JSON.stringify(resp.data.user))
      setLoginStatus(true);
    }
    else {
      alert("something went wrong")
    }
    loadingStore.setIsloading(false);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const setValue = (key, value) => {
    setConfig(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  const login = async () => {
    try {
      const { email, password } = config;
      if (!email, !password) {
        alert("Missing credentials");
        return;
      }

      loadingStore.setIsloading(true);
      const resp = await authStore.login(config);
      if (resp.done) {
        localStorage.setItem("token", resp.data.token)
        localStorage.setItem("user", JSON.stringify(resp.data.user))
        setLoginStatus(true);
      }
      else {
        console.log('resp: ', resp);
        alert(resp.data)
      }
      loadingStore.setIsloading(false);
    } catch (error) {
      alert("something went wrong")
    }

  }


  return (
    <div className='auth-container' >
      <div className='auth' >
        <div className='header' >
          <img src={Logo} />
          <h2>Login TherapyAI</h2>
          <span>Don't have an account yet? <Link to={"/register"} >Register</Link></span>
        </div>
        <div className='body' >
          <input onChange={(event) => { setValue('email', event.target.value) }} type='text' placeholder='Email Address' />
          <input onChange={(event) => { setValue('password', event.target.value) }} type='password' placeholder='Password' />
          <button onClick={() => login()} >Login</button>
        </div>
        <span className='or' >OR</span>
        <div className='footer' >
          <GoogleOAuthProvider clientId='1085084085409-0rva6bhau3ir7de94q3q99l9skp4420o.apps.googleusercontent.com'>
            <GoogleLogin width={'300px'} onSuccess={responseMessage} onError={errorMessage} useOneTap />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  )
}

export default Login
