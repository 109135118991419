import React from 'react'
import './index.css'
import callStore from '../../stores/callStore'
import { KingIcon } from '../../assets'
import { formatTimestamp, getDuration } from '../../helpers/functions'
import PrimaryButton from '../PrimaryButton'
import loadingStore from '../../stores/loadingStore'
import { Link, useSearchParams } from 'react-router-dom'
import CallDetail from '../CallDetail'


const HasCallHome = () => {

  const { calls } = callStore;
  let [searchParams, setSearchParams] = useSearchParams();

  const startCall = async () => {
    loadingStore.setIsloading(true);
    await callStore.create();
    callStore.setIsCalling(true)
    loadingStore.setIsloading(false);
  }
  const params_call_id = searchParams.get('call_id');
  return (
    <div className='has-call-home' >
      <div className='call-list' >
        {
          calls.map(call => {
            const { start_timestamp, end_timestamp, call_id } = call;
            const duration = getDuration(start_timestamp, end_timestamp);
            const date = formatTimestamp(start_timestamp);
            return (
              <Link to={`?call_id=${call_id}`} className='call-list-box' style={{ backgroundColor: call_id === params_call_id && "#424242" }} >
                <img src={KingIcon} />
                <div>
                  <b>{duration}</b>
                  <span>{date}</span>
                </div>
              </Link>
            )
          })
        }
      </div>
      <div className='right' >
        {
          params_call_id === null ?
            <PrimaryButton text={"Start Therapy"} action={startCall} />
            : <CallDetail call_id={params_call_id} />
        }
      </div>
    </div>
  )
}

export default HasCallHome
