import { makeAutoObservable } from "mobx";
import apiManager from "../Apimanager";
import agentStore from "./agentStore";

class CallStore {

  calls = [];
  isCalling = false;
  call = null;

  setCalls = (calls) => {
    this.calls = calls
  }

  setIsCalling = (stat) => {
    this.isCalling = stat
  }


  constructor() {
    makeAutoObservable(this)
  }

  all = async () => {
    try {
      const resp = await apiManager.request("/call/all", "post", {});
      this.calls = resp.data.calls;
      return resp.data.calls;

    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

  create = async () => {
    try {
      const agent = await agentStore.get();
      const { agent_id } = agent;

      const resp = await apiManager.request("/call/create", "post", { agent_id });
      this.call = resp.data.call;
      await this.all();
      return resp.data.call;

    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

}

const callStore = new CallStore();
export default callStore;