// CircularAudioSpectrum.js
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useAudioAnalyzer } from './useAudioAnalyzer';
import { RoundedBox } from '@react-three/drei';
import './index.css'


const Bars = ({ dataArray }) => {
  const barsRef = useRef([]);
  const numBars = 64; // Number of bars in the circle
  const radius = 5; // Radius of the circle

  useFrame(() => {
    const data = dataArray();
    barsRef.current.forEach((bar, index) => {
      if (bar) {
        const scale = data[index] / 512;
        bar.scale.y = Math.max(scale * 5, 0.1);
      }
    });
  });

  return (
    <>
      {Array.from({ length: numBars }).map((_, index) => {

        var angle = (index / numBars) * Math.PI * 2;
        var color = "";
        if (!(44 < index && index < 53)) {
          angle = 0
        } else {
          if (index < 47) {
            color = "#eb2367"
          } else if (index < 50) {
            color = "#bd2c76"
          } else {
            color = "#963484"
          }
        }

        const x = Math.cos(angle) * radius;
        const z = Math.sin(angle) * radius;

        return (
          <mesh
            key={index}
            ref={(ref) => {
              barsRef.current[index] = ref;
            }}
            position={[x, 0, z / 3]}
            rotation={[0, -angle, 0]}
          >
            <RoundedBox args={[0.2, 1, 0.2]} radius={0.09} smoothness={4}>
              <meshStandardMaterial color={color} /> {/* Gradient color */}
            </RoundedBox>
          </mesh>
        );
      })}
    </>
  );
};

const AudioSpectrum = () => {
  const getFrequencyData = useAudioAnalyzer();

  return (
    <div className='audio-spectrum' >
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Bars dataArray={getFrequencyData} />
      </Canvas>
    </div>
  );
};

export default AudioSpectrum;
