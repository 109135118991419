
import axios from "axios";

class ApiManager {
  // url = "http://localhost:5002/v1";
  url = "https://api.therapyai.app/v1";

  request = async (path, method, body) => {
    try {
      const resp = await axios(this.url + path, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: body,
      });
      return resp.data;
    } catch (error) {
      console.log('error: ', error);

      console.log(error);
      return {
        done: false,
        data: {
          error: error,
        },
      };
    }
  };

}

const apiManager = new ApiManager();
export default apiManager;