import React, { useEffect, useState } from 'react'
import './index.css'
import Header from '../../components/Header'
import NoCallHome from '../../components/NoCallHome'
import Loading from '../../components/Loading'
import callStore from '../../stores/callStore'
import loadingStore from '../../stores/loadingStore'
import { checkMicPermission } from '../../helpers/functions'
import Call from '../../components/Call'
import { observer } from 'mobx-react-lite'
import HasCallHome from '../../components/HasCallHome'
import { useParams, useSearchParams } from 'react-router-dom'


const Home = observer(() => {

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const temp = async () => {
      loadingStore.setIsloading(true);
      await callStore.all();
      await checkMicPermission();
      setReady(true)
      loadingStore.setIsloading(false);
    }



    temp();
  }, [])

  const { calls, isCalling } = callStore;
  const { loading } = loadingStore;

  return (
    <div className='home' >
      <Header />
      {loading && <Loading />}
      {calls.length === 0 && <div className='body' > <NoCallHome /> </div>}
      {calls.length > 0 && ready &&
        (
          isCalling ?
            <div className='body'> < Call /> </div>
            :
            <div className='body' > <HasCallHome /> </div>
        )
      }
    </div >
  )
})

export default Home
