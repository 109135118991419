import apiManager from "../Apimanager";

class AgentStore {
  all = async () => {
    try {
      const resp = await apiManager.request("/agent/all", "get");
      return resp.data.agents;

    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

  get = async () => {
    try {
      const all = await this.all();
      return all[0];
    } catch (error) {
      console.log('error: ', error);
      return { done: false };
    }
  }

}

const agentStore = new AgentStore();
export default agentStore;