import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { BillingIcon, Logo, LogoutIcon, SettingsIcon, UserIcon } from '../../assets'
import { Link, useNavigate } from 'react-router-dom'
import authStore from '../../stores/authStore'

const Header = () => {

  const nav = useNavigate();
  const [picture, setPicture] = useState(UserIcon);
  const [toggle, setToggle] = useState(false);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.userdata) {
      setPicture(user.userdata.picture)
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  const toggleSettings = () => {
    setToggle(!toggle)
  }

  const goPage = (q) => {
    nav(q);
    toggleSettings()
  }

  const logout = () => {
    authStore.logout()
    nav("/login")
  }

  return (
    <div className='main-header' >
      <Link to={"/home"} className='logo' >
        <img src={Logo} />
      </Link>
      <div ref={containerRef} className='settings-container' >
        <div onClick={() => toggleSettings()} className='profile' >
          <img src={picture} />
        </div>
        {toggle && <div className='settings' >
          <button onClick={() => goPage("/account")} ><img src={UserIcon} /> <span>Account</span></button>
          <button onClick={() => goPage("/billing")} ><img src={BillingIcon} /> <span>Billing</span></button>
          <button onClick={() => goPage("/settings")} ><img src={SettingsIcon} /> <span>Settings</span></button>
          <div className='line' ></div>
          <button onClick={() => logout()} ><img src={LogoutIcon} /> <span>Log out</span></button>
        </div>}
      </div>
    </div>
  )
}

export default Header
