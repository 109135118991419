export const checkMicPermission = async () => {
  try {
    const status = await navigator.permissions.query({ name: 'microphone' });
    if (status.state != 'granted') {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    }
  } catch (error) {
    console.log('error: ', error);

  }
}

export const getDuration = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const durationInSeconds = Math.floor((end - start) / 1000);

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  let durationString = '';
  if (hours > 0) {
    durationString += `${hours} hour${hours !== 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    durationString += `${minutes} min${minutes !== 1 ? 's' : ''} `;
  }
  if (seconds > 0 || durationString === '') {
    durationString += `${seconds} sec${seconds !== 1 ? 's' : ''}`;
  }

  return durationString.trim();
}

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}