import React from 'react';
import Header from '../../components/Header';

const PrivacyPolicy = () => {
  return (
    <div className='text-page-container' >
      <Header />
      <div className='text-page'>
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> 09/09/2024</p>
        <p>
          Welcome to TherapyAI! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our website, <a href="https://therapyai.app">https://therapyai.app</a>.
        </p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>Personal Data:</strong> We collect your name and email address when you interact with our website.</li>
          <li><strong>Non-Personal Data:</strong> We collect non-personal information through web cookies to enhance your experience on our site.</li>
        </ul>

        <h2>2. Purpose of Data Collection</h2>
        <p>
          We use the information we collect from you for the following purposes:
        </p>
        <ul>
          <li><strong>Order Processing:</strong> To process and manage your orders and requests.</li>
        </ul>

        <h2>3. Data Sharing</h2>
        <p>
          We do not share your personal data with any third parties. Your information is kept confidential and is only used for the purposes stated above.
        </p>

        <h2>4. Children's Privacy</h2>
        <p>
          TherapyAI is not intended for use by children under the age of 13. We do not knowingly collect personal data from children. If we become aware that we have inadvertently collected such data, we will take steps to delete it.
        </p>

        <h2>5. Updates to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be communicated to you via email.
        </p>

        <h2>6. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:hello@retryapps.co">hello@retryapps.co</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
