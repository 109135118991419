import { makeAutoObservable } from "mobx";

class LoadingStore {

  loading = false;

  setIsloading = (status) => {
    this.loading = status
  }


  constructor() {
    makeAutoObservable(this)
  }


}

const loadingStore = new LoadingStore();
export default loadingStore;