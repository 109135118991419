import React, { useEffect } from 'react';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import { observer } from "mobx-react-lite"
import Settings from './pages/Settings';
import Account from './pages/Account';
import Billing from './pages/Billing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';


const RedirectToHome = observer(() => {
  return (
    <Navigate to={"/home"} />
  )
});

const Root = observer(({ }) => {

  const nav = useNavigate();


  const isRegister = window.location.pathname;

  useEffect(() => {
    if (!localStorage.getItem("token") && isRegister !== "/register") {
      nav("/login")
    }
  }, [])

  return (
    <main>
      <Outlet />
    </main>
  )
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "login",
        element: <Login />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "account",
        element: <Account />
      },
      {
        path: "billing",
        element: <Billing />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />
      },
      {
        path: "terms-of-use",
        element: <TermsOfUse />
      },
      {
        path: "",
        element: <RedirectToHome />
      },
    ]
  },
]);



function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
