import React, { useEffect, useState } from 'react'
import './index.css'
import Header from '../../components/Header'
import SettingsMenu from '../../components/SettingsMenu'
import { MailIcon, PadlockIcon, UserIcon } from '../../assets'
import { Link } from 'react-router-dom'

const Account = () => {

  const [email, setEmail] = useState();
  const [picture, setPicture] = useState(UserIcon);


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setEmail(user.email)
    }
    if (user && user.userdata) {
      setPicture(user.userdata.picture)
    }

  }, [])

  return (
    <div className='home' >
      <Header />
      <div className='body' >
        <div className='account-home' >
          <SettingsMenu page={"account"} />
          <div className='account-inner' >
            <b>Account</b>
            <div className='profile-img' >
              <img src={picture} />
            </div>
            <div className='box' ><img src={MailIcon} /> <span>{email}</span></div>
            <Link className='box' to={"/change-password"} ><img src={PadlockIcon} /> <span>Change Password</span></Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Account
