export const Logo = require('./logo.png');
export const UserIcon = require('./user.png');
export const SettingsIcon = require('./settings.png');
export const LogoutIcon = require('./logout.png');
export const BillingIcon = require('./bill.png');
export const KingIcon = require('./king.png');
export const TimeIcon = require('./time-left.png');
export const CloseIcon = require('./close.png');
export const InsuranceIcon = require('./insurance.png');
export const ContractIcon = require('./contract.png');
export const MailIcon = require('./email.png');
export const PadlockIcon = require('./padlock.png');